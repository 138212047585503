import styled from '@emotion/styled';
import { Breakpoint } from '@pafcloud/style';
import type { GridBreakpoint } from '../grid';
import { getGridBreakpoint } from '../grid';
import type { AspectRatio } from '../definitions';

const getBreakpointStyle = (breakpoint: GridBreakpoint, aspectRatio: AspectRatio) => {
  const gridBreakpoint = getGridBreakpoint(breakpoint, aspectRatio);

  return {
    '--columns': `${gridBreakpoint.columns}`,
    '--narrow-span': `${gridBreakpoint.narrow}`,
    '--wide-span': `${gridBreakpoint.wide}`,
  };
};

export const Grid = styled.ul({
  ...getBreakpointStyle('default', 'portrait'),

  [Breakpoint.TabletOrLarger]: {
    ...getBreakpointStyle(Breakpoint.TabletOrLarger, 'portrait'),
  },
  [Breakpoint.LaptopOrLarger]: {
    ...getBreakpointStyle(Breakpoint.LaptopOrLarger, 'portrait'),
  },
  [Breakpoint.BigScreenOrLarger]: {
    ...getBreakpointStyle(Breakpoint.BigScreenOrLarger, 'portrait'),
  },
  [Breakpoint.HDScreenOrLarger]: {
    ...getBreakpointStyle(Breakpoint.HDScreenOrLarger, 'portrait'),
  },

  display: 'grid',
  gridTemplateColumns: 'repeat(var(--columns), minmax(0, 1fr))',
  gap: 'var(--gap)',
  margin: 0,
  paddingLeft: 'var(--full-width-margin)',
  paddingRight: 'var(--full-width-margin)',
  counterReset: 'game-counter 0',
  listStyle: 'none',
});
