import { graphql, useFragment } from 'react-relay/hooks';
import { useBonus } from '@pafcloud/contexts';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { useGetTurnoverContribution_game$key } from './__generated__/useGetTurnoverContribution_game.graphql';

const useGetTurnoverContributionGame = graphql`
  fragment useGetTurnoverContribution_game on Game {
    turnoverContribution
    marketGameCategory
    category
  }
`;

export const useGetTurnoverContribution = (props: useGetTurnoverContribution_game$key): number | null => {
  const game = useFragment(useGetTurnoverContributionGame, props);
  const { turnover } = useBonus();

  if (game == null || turnover == null) {
    return null;
  }

  const { category, marketGameCategory, turnoverContribution } = game;

  // null and 0 turnover contribution on a game overrides the turnover contribution at category level.
  if (turnoverContribution == null || turnoverContribution === 0) {
    return null;
  }

  if ($buildEnv.market === 'spain') {
    if (category === 'video-poker') {
      return (
        turnover.step.turnoverCategories?.find((turnoverCategory) => turnoverCategory.category === 'VIDEOPOKER')
          ?.contributionPercentage ?? null
      );
    }
  }

  const contribution = turnover.step.turnoverCategories?.find((turnoverCategory) => {
    return turnoverCategory.category === marketGameCategory;
  });

  return contribution?.contributionPercentage ?? null;
};
