/**
 * @generated SignedSource<<3a81bbfdf5dc612b8506cec26c38e71d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GridGameList_block$data = {
  readonly gameList: {
    readonly " $fragmentSpreads": FragmentRefs<"GridGameList_gameList" | "useTrackGame_gameList">;
  };
  readonly numberOfRows: number;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SeeAllGamesLink_block" | "ShowTotalGameCount_block" | "useTrackGame_trackable">;
  readonly " $fragmentType": "GridGameList_block";
};
export type GridGameList_block$key = {
  readonly " $data"?: GridGameList_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"GridGameList_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GridGameList_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GameList",
      "kind": "LinkedField",
      "name": "gameList",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GridGameList_gameList"
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useTrackGame_gameList",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gameListId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "internalName",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfRows",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useTrackGame_trackable",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "track",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trackId",
              "storageKey": null
            }
          ],
          "type": "Trackable",
          "abstractKey": "__isTrackable"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SeeAllGamesLink_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShowTotalGameCount_block"
    }
  ],
  "type": "GridGameListBlock",
  "abstractKey": null
};

(node as any).hash = "80e28b2d2744181804912ea236b35e05";

export default node;
