import styled from '@emotion/styled';
import { Color } from '@pafcloud/style';
import { backgroundStyle } from './sharedFullWidthBackground';

export const Background = styled.div(backgroundStyle, {
  'video, picture': {
    opacity: 0.15,
  },

  '::before, ::after': {
    pointerEvents: 'none',
    content: '""',
    position: 'absolute',
    display: 'block',
    width: '100%',
  },

  // Top radial glow
  '::before': {
    top: 0,
    height: '100%',
    backgroundImage: `radial-gradient(ellipse at center 10%, ${Color.Primitive.Primary}21, transparent 33%, transparent)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: '100% 66%',
  },

  // Bottom radial glow
  '::after': {
    bottom: 0,
    height: 'max(10%, 200px)',
    backgroundImage: `
        linear-gradient(0deg, ${Color.Primitive.Primary}08, transparent 16px),
        radial-gradient(ellipse at center bottom, ${Color.Primitive.Primary}14, transparent 30%)
        `,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom center',
    backgroundSize: '100% 100%, 300% 110%',
  },
});
