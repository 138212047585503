import type { FC } from 'react';
import { useMemo, useState, useRef } from 'react';
import { graphql, useFragment, usePaginationFragment } from 'react-relay/hooks';
import { toast } from 'react-toastify';
import { useTranslation } from '@pafcloud/i18n';
import { useTrackClickedGame, useTrackViewedGame } from '@pafcloud/contexts';

import type { PaginatedFilterGridGameListQuery } from './__generated__/PaginatedFilterGridGameListQuery.graphql';
import type { FilterGridGameList_gameList$key } from './__generated__/FilterGridGameList_gameList.graphql';
import type { FilterGridGameList_trackable$key } from './__generated__/FilterGridGameList_trackable.graphql';
import {
  Cover,
  GameListHeader,
  GameListSection,
  Grid,
  ListItem,
  ShowMoreButton,
  Thumbnail,
  GameLink,
  FlexibleGameListTitle,
} from './parts';
import { Decorations } from './decorations/Decorations';
import { useGridColumns } from './useGridColumns';
import { getPaginatedGrid } from './utils/getPaginatedGrid';
import { getPortraitImageSizes } from './grid';

const gridGameListFragment = graphql`
  fragment FilterGridGameList_gameList on GameList
  @argumentDefinitions(count: { type: Int, defaultValue: 40 }, tag: { type: String }, cursor: { type: String })
  @refetchable(queryName: "PaginatedFilterGridGameListQuery") {
    ...useTrackGame_gameList
    games(first: $count, tag: $tag, after: $cursor) @connection(key: "PaginatedFilterGridGameList_GameList_games") {
      ...useTrackGame_games
      edges {
        node {
          ...GameLink_game
          ...Decorations_game
          ...GameLogo_game
          name
          supplier
          slug
          thumbnail(layout: PORTRAIT)
        }
      }
    }
  }
`;

const trackableFragment = graphql`
  fragment FilterGridGameList_trackable on Trackable {
    ...useTrackGame_trackable
  }
`;

type Props = {
  gameList: FilterGridGameList_gameList$key;
  title: string;
  tracking: FilterGridGameList_trackable$key | null;
};

const NUMBER_OF_ROWS_IN_GAME_LIST = 6;

export const FilterGridGameList: FC<Props> = ({ title, gameList, tracking }) => {
  const gridRef = useRef<HTMLUListElement>(null);
  const { t } = useTranslation('game-list');
  const imageSizes = useMemo(() => getPortraitImageSizes('narrow'), []);

  const { data } = usePaginationFragment(gridGameListFragment, gameList);
  const trackable = useFragment(trackableFragment, tracking);

  const numberOfColumns = useGridColumns(gridRef, 'narrow');

  const [rows, setRows] = useState(NUMBER_OF_ROWS_IN_GAME_LIST);
  const pagination = usePaginationFragment<PaginatedFilterGridGameListQuery, FilterGridGameList_gameList$key>(
    gridGameListFragment,
    gameList,
  );
  const { games } = pagination.data;

  const onGameClicked = useTrackClickedGame({
    games,
    gameList: data,
    trackable: trackable ?? null,
  });

  const onGameViewed = useTrackViewedGame({
    games,
    gameList: data,
    trackable: trackable ?? null,
  });

  const paginatedGrid = getPaginatedGrid(pagination, {
    edges: games.edges,
    columns: numberOfColumns,
    rows,
    setRows,
    onError: () => toast.error(t('load-more-failed')),
  });
  const { data: visibleGames, loadNext, hasNext, isLoadingNext } = paginatedGrid;

  if (visibleGames.length === 0) {
    return null;
  }

  return (
    <GameListSection aspectRatio="portrait" columnWidth="narrow">
      <GameListHeader>
        <FlexibleGameListTitle>{title}</FlexibleGameListTitle>
      </GameListHeader>

      <Grid ref={gridRef}>
        {visibleGames.map(({ node: game }, index) => (
          <ListItem key={game.slug}>
            <GameLink game={game} index={index} onView={onGameViewed} onClick={onGameClicked}>
              <Cover>
                <Thumbnail gameName={game.name} thumbnail={game.thumbnail} sizes={imageSizes} />
                <Decorations game={game} />
              </Cover>
            </GameLink>
          </ListItem>
        ))}
      </Grid>

      {hasNext && (
        <ShowMoreButton
          variant="secondary"
          colorstyle="accent"
          type="button"
          size="default"
          isLoading={isLoadingNext}
          onClick={loadNext}
        >
          {t('show-more')}
        </ShowMoreButton>
      )}
    </GameListSection>
  );
};
