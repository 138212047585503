import styled from '@emotion/styled';

export type GameItemSize = 'small' | 'normal' | 'large';

export const BadgeSize = {
  small: {
    size: 28,
  },
  normal: {
    size: 42,
  },
  large: {
    size: 62,
  },
};

type BadgeWrapperProps = {
  size: GameItemSize;
  rotateBadge?: true;
};

const BadgeCornerWrapper = styled.div<BadgeWrapperProps>(({ rotateBadge }) => ({
  position: 'absolute',
  zIndex: 1,
  transform: rotateBadge && 'rotate(-7deg)',
}));

export const GameItemLeftCornerBadgeWrapper = styled(BadgeCornerWrapper)(({ size }) => ({
  top: `-${BadgeSize[size].size / 8}px`,
  left: `-${BadgeSize[size].size / 8}px`,
}));

export const GameItemRightCornerBadgeWrapper = styled(BadgeCornerWrapper)(({ size }) => ({
  top: `-${BadgeSize[size].size / 8}px`,
  right: `-${BadgeSize[size].size / 8}px`,
}));
