/**
 * @generated SignedSource<<10e457b2ee2a8ab69173dc6e72d7f9c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SeeAllGamesLink_block$data = {
  readonly link: {
    readonly ariaLabel: string | null;
    readonly text: string;
    readonly url: string;
  } | null;
  readonly " $fragmentType": "SeeAllGamesLink_block";
};
export type SeeAllGamesLink_block$key = {
  readonly " $data"?: SeeAllGamesLink_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"SeeAllGamesLink_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SeeAllGamesLink_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Link",
      "kind": "LinkedField",
      "name": "link",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ariaLabel",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CommonGameListBlock",
  "abstractKey": "__isCommonGameListBlock"
};

(node as any).hash = "99c85df7e7a1617bcda0b69f92174166";

export default node;
