import type { FormatCurrencyProps } from '@pafcloud/locale';
import { useFormatAmount } from './useFormatAmount';

type Overrides = Omit<FormatCurrencyProps, 'amount' | 'locale'>;

export function useFormatAmountRange(overrides: Overrides = {}) {
  const formatAmount = useFormatAmount({ withCurrency: false, decimals: false, ...overrides });

  return function formatAmountRange(min: number, max: number, localOverrides: Overrides = {}) {
    const minAmount = formatAmount(min, { decimals: min % 1 !== 0, withCurrency: false, ...localOverrides });
    const maxAmount = formatAmount(max, { decimals: max % 1 !== 0, withCurrency: false, ...localOverrides });

    if (overrides.withCurrency) {
      return formatAmount(0)?.replace('0', `${minAmount}-${maxAmount}`);
    }

    return `${minAmount}-${maxAmount}`;
  };
}
