import type { FC } from 'react';
import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { useTrackClickedGame, useTrackViewedGame } from '@pafcloud/contexts';
import { Breakpoint, Font, FontTextSize } from '@pafcloud/style';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { HorizontalScroller } from '@pafcloud/base-components';
import type { NumberedGameList_block$key } from './__generated__/NumberedGameList_block.graphql';
import { Decorations } from './decorations/Decorations';
import {
  Cover,
  GameListHeader,
  GameListSection,
  Heading,
  Thumbnail,
  GameLink,
  SeeAllGamesLink,
  GameListItem,
} from './parts';
import { ShowTotalGamesCount } from './parts/ShowTotalGameCount';
import { getPortraitImageSizes } from './grid';
import { FlexibleGameListColors } from './flexible-game-list-colors';

const NumberedListGameListScroll = styled(HorizontalScroller)({
  counterReset: 'game-counter 0',

  '--number-of-items': 1.33,

  [Breakpoint.TabletOrLarger]: {
    '--number-of-items': 3,
  },
  [Breakpoint.LaptopOrLarger]: {
    '--number-of-items': 4,
  },
  [Breakpoint.BigScreenOrLarger]: {
    '--number-of-items': 5,
  },
});

const HighlightedInfo = styled.div({
  counterIncrement: 'game-counter',

  position: 'relative',
  overflow: 'hidden',
  zIndex: -1, // place the info behind the thumbnail

  display: 'flex',
  alignItems: 'center',
  marginTop: -1, // prevent subpixel gap
  padding: '0 16px',
  borderBottomLeftRadius: 'var(--radius)',
  borderBottomRightRadius: 'var(--radius)',
  background: FlexibleGameListColors.NumberedGameListInfoBackground,

  fontSize: FontTextSize.Normal,

  [Breakpoint.HDScreenOrLarger]: {
    fontSize: FontTextSize.Big,
  },
});

const Counter = styled.div({
  overflow: 'visible',
  position: 'absolute',
  top: '-0.4ex',
  right: 0,
  zIndex: -1,
  paddingRight: '0.1em',

  fontFamily: $buildEnv.theme === 'classic' || $buildEnv.theme === 'momentum' ? 'arial, sans-serif' : Font.Heading,
  fontSize: '7.2em',
  fontWeight: 900,
  fontStyle: 'italic',

  letterSpacing: '-0.1em',
  textAlign: 'right',
  lineHeight: 1.2,

  color: 'transparent',
  background: FlexibleGameListColors.NumberedGameListCounterText,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',

  '::before': {
    content: 'counter(game-counter)',
  },
});

const InfoWrapper = styled.div({
  overflow: 'hidden', // fixes text-overflow hidden
  padding: '1.5em 0',

  color: FlexibleGameListColors.NumberedGameListText,
  textShadow: FlexibleGameListColors.NumberedGameListTextShadow,
});

const GameName = styled.span({
  overflow: 'hidden',
  display: 'block',
  width: '100%',

  fontSize: '1em',
  fontWeight: 600,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  lineHeight: 1.25,
});

const GameTags = styled.ul({
  margin: 0,
  marginTop: '0.2rem',
  padding: 0,
  listStyle: 'none',
  fontSize: '0.65em',
  textTransform: 'uppercase',
  lineHeight: 1,
  opacity: 0.7,

  li: {
    display: 'inline-block',
  },

  'li + li': {
    '::before': {
      content: '"|"',
      opacity: 0.5,
      margin: '0 4px',
    },
  },
});

const CardThumbnail = styled(Thumbnail)({
  img: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
});

const gameListBlockFragment = graphql`
  fragment NumberedGameList_block on NumberedGameListBlock {
    gameList {
      ...useTrackGame_gameList
      games(first: 20) {
        ...useTrackGame_games
        ...ShowTotalGameCount_games
        edges {
          node {
            ...GameLink_game
            ...Decorations_game
            name
            slug
            thumbnail(layout: PORTRAIT)
            supplier
          }
        }
      }
    }
    title
    ...useTrackGame_trackable
    ...SeeAllGamesLink_block
    ...ShowTotalGameCount_block
  }
`;

type Props = {
  block: NumberedGameList_block$key;
};

export const NumberedGameList: FC<Props> = ({ block }) => {
  const blockData = useFragment(gameListBlockFragment, block);
  const imageSizes = useMemo(() => getPortraitImageSizes('wide'), []);

  const onGameClicked = useTrackClickedGame({
    games: blockData.gameList.games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  const onGameViewed = useTrackViewedGame({
    games: blockData.gameList.games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  if (!blockData.gameList.games.edges.length) {
    return null;
  }

  return (
    <GameListSection aspectRatio="portrait" columnWidth="wide">
      <GameListHeader>
        <Heading title={blockData.title} />
        <SeeAllGamesLink block={blockData} />
        <ShowTotalGamesCount block={blockData} games={blockData.gameList.games} />
      </GameListHeader>

      <NumberedListGameListScroll>
        {blockData.gameList.games.edges.map(({ node: game }, index) => (
          <GameListItem key={game.slug}>
            <GameLink game={game} onClick={onGameClicked} index={index} onView={onGameViewed}>
              <Cover>
                <CardThumbnail gameName={game.name} thumbnail={game.thumbnail} sizes={imageSizes} />
                <Decorations game={game} />
                <HighlightedInfo>
                  <Counter />
                  <InfoWrapper>
                    <GameName>{game.name}</GameName>
                    <GameTags>{game.supplier}</GameTags>
                  </InfoWrapper>
                </HighlightedInfo>
              </Cover>
            </GameLink>
          </GameListItem>
        ))}
      </NumberedListGameListScroll>
    </GameListSection>
  );
};
