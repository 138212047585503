import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Item = css({
  position: 'relative',
  zIndex: 0,
  width: '100%',
  margin: 0,
  padding: 0,
  listStyle: 'none',
});

export const ListItem = styled.li(Item, {
  scrollSnapAlign: 'start',
  gridColumn: 'span var(--narrow-span, 1)',

  '[data-column-width="wide"] &': {
    gridColumn: 'span var(--wide-span, 2)',
  },
});
export const GameListItem = styled.div(Item, {
  '&&&': {
    flexGrow: 0,
  },
});
