import styled from '@emotion/styled';
import type { FC } from 'react';
import { FontTextSize } from '@pafcloud/style';
import { FlexibleGameListColors } from '../flexible-game-list-colors';

const GameInfoWrapper = styled.div({
  overflow: 'hidden',
  marginTop: 8,
  fontSize: FontTextSize.Normal,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const Text = styled.span({
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const GameName = styled(Text)({
  color: FlexibleGameListColors.GameInfoNameText,
  fontSize: FontTextSize.Normal,
  fontWeight: 'bold',
});

const ExtraInfo = styled(Text)({
  color: FlexibleGameListColors.GameInfoExtraInfoText,
  fontSize: FontTextSize.Tiny,
});

type Props = {
  name: string;
  supplier?: string;
};

export const GameInfo: FC<Props> = ({ name, supplier }) => {
  return (
    // aria-hidden makes sense here as each game link already has an aria-label
    <GameInfoWrapper aria-hidden="true">
      <GameName>{name}</GameName>
      {supplier && <ExtraInfo>{supplier}</ExtraInfo>}
    </GameInfoWrapper>
  );
};
