import styled from '@emotion/styled';
import { NoMotionPreference } from '@pafcloud/style';
import { PartsZIndex } from '../definitions';
import { FlexibleGameListColors } from '../flexible-game-list-colors';

export const Cover = styled.div({
  position: 'relative',
  userSelect: 'none',
  borderRadius: 'var(--radius)',
  boxShadow: FlexibleGameListColors.GameItemShadow,

  [NoMotionPreference]: {
    transition: 'transform 0.075s ease-in-out, box-shadow 0.22s ease-out',
  },

  '@media(hover: hover) and (pointer: fine)': {
    'a:hover &': {
      zIndex: 2,
      transform: 'translateY(-2px) scale(1.025)',
      boxShadow: FlexibleGameListColors.GameItemShadowHover,
    },
  },

  'a:focus-visible &': {
    '::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: PartsZIndex.FocusRing,
      borderRadius: 'var(--radius)',
      border: FlexibleGameListColors.GameItemFocus,
    },
  },

  '[aria-disabled] &': {
    filter: FlexibleGameListColors.MaintenanceOverlayFilter,
    background: FlexibleGameListColors.MaintenanceOverlay,

    img: {
      opacity: 0.6,
    },
  },
});
