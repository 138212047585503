import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import { Breakpoint } from '@pafcloud/style';
import type { AspectRatio, ColumnWidth } from '../definitions';
import { AspectRatioValues } from '../definitions';
import { FlexibleGameListColors } from '../flexible-game-list-colors';

export const GameList = styled.section({
  position: 'relative',
  gridColumn: '1 / -1', // full width
  minWidth: 0,
  marginBottom: 16,
  '--radius': `${FlexibleGameListColors.GameItemBorderRadius}`,
  '--gap': '8px',

  [Breakpoint.LaptopOrLarger]: {
    marginBottom: 40,
    '--gap': '16px',
  },

  // The & is needed here even tho it is usually not needed in nested selectors.
  '&[data-aspect-ratio="portrait"]': {
    '--aspect-ratio': AspectRatioValues.portrait.ratio,
    '--aspect-ratio-percentage': AspectRatioValues.portrait.percentage,
  },

  '&[data-aspect-ratio="landscape"]': {
    '--aspect-ratio': AspectRatioValues.landscape.ratio,
    '--aspect-ratio-percentage': AspectRatioValues.landscape.percentage,
  },

  '&[data-aspect-ratio="square"]': {
    '--aspect-ratio': AspectRatioValues.square.ratio,
    '--aspect-ratio-percentage': AspectRatioValues.square.percentage,
  },
});

type Props = {
  aspectRatio: AspectRatio;
  columnWidth: ColumnWidth;
  children: ReactNode;
  className?: string;
};

export const GameListSection: FC<Props> = ({ aspectRatio, children, columnWidth, className }) => {
  return (
    <GameList data-aspect-ratio={aspectRatio} data-column-width={columnWidth} className={className}>
      {children}
    </GameList>
  );
};
