import { imageLoader } from './image-loader';

type ImageRemapProps = {
  sizes: number[];
  src: string;
  quality?: number;
  blur?: number;
};

export const imageRemapSize = ({ sizes, src, quality, blur }: ImageRemapProps) => {
  return sizes.map((size) => `${imageLoader({ src: src ?? '', width: size, quality, blur: blur })} ${size}w`).join(',');
};
