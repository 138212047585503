import type { RefObject } from 'react';
import { useEffect, useState } from 'react';
import { useDebounce, useResizeObserver } from '@pafcloud/react-hook-utils';
import type { ColumnWidth } from './definitions';

const MIN_COLUMN_COUNT = 1;

const getColumns = (element: Element | null, columnWidth: ColumnWidth) => {
  if (element == null) {
    return MIN_COLUMN_COUNT;
  }

  const span = Number(getComputedStyle(element).getPropertyValue(`--${columnWidth}-span`)) || 1;
  const columns = Number(getComputedStyle(element).getPropertyValue('--columns')) / span;
  if (isNaN(columns)) {
    return MIN_COLUMN_COUNT;
  }

  return Math.max(Math.floor(columns), MIN_COLUMN_COUNT);
};

export const useGridColumns = (ref: RefObject<Element>, columnWidth: ColumnWidth) => {
  const [columns, setColumns] = useState(getColumns(ref.current, columnWidth));

  const { debouncedFn, cancel } = useDebounce(() => setColumns(getColumns(ref.current, columnWidth)), {
    wait: 50,
  });

  useResizeObserver(ref, debouncedFn);

  useEffect(() => cancel, [cancel]);

  return columns;
};
