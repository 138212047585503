import styled from '@emotion/styled';
import type { FC } from 'react';
import { Heading, PrimaryHeading } from '@pafcloud/base-components';

const Title = styled(Heading)(PrimaryHeading, {
  marginBottom: 16,
  zIndex: 1,
});

type GameListTitleProps = {
  title: string;
  accessibleNameId?: string;
};

export const GameListTitle: FC<GameListTitleProps> = ({ title, accessibleNameId }) => {
  return <Title id={accessibleNameId}>{title}</Title>;
};
