import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  NumberedGameListText: Color.Primitive.PrimaryContrast,
  NumberedGameListInfoBackground: Color.Primitive.Primary,
  NumberedGameListCounterText: Color.Primitive.PrimaryContrast,
} as const;
