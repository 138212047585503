import type { FC } from 'react';
import { BonusProductUnitsBadge } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import type { GameItemSize } from './game-item-styles/gameItemSharedStyles';
import { BadgeSize, GameItemRightCornerBadgeWrapper } from './game-item-styles/gameItemSharedStyles';

type Props = {
  showNewBadge?: boolean;
  promoted?: boolean;
  showTurnover?: boolean;
  showFreespins?: boolean;
  showBingoTickets?: boolean;
  turnoverContribution?: number | null;
  size: GameItemSize;
  inMaintenance?: boolean;
};

type GameItemRightCornerBadgeProps = Pick<Props, 'size' | 'showFreespins' | 'showBingoTickets'> & {
  rounds: number;
  tickets: number;
};

export const GameItemRightCornerBadge: FC<GameItemRightCornerBadgeProps> = ({
  size,
  rounds,
  tickets,
  showFreespins,
  showBingoTickets,
}) => {
  const { t } = useTranslation('game-list');

  if (showFreespins && rounds > 0) {
    return (
      <GameItemRightCornerBadgeWrapper size={size} title={t('badge.title.freespins', { numberOfRounds: rounds })}>
        <BonusProductUnitsBadge size={BadgeSize[size].size} productUnits={rounds} />
      </GameItemRightCornerBadgeWrapper>
    );
  }

  if (showBingoTickets && tickets > 0) {
    return (
      <GameItemRightCornerBadgeWrapper size={size} title={t('badge.title.bingo-tickets', { numberOfTickets: tickets })}>
        <BonusProductUnitsBadge size={BadgeSize[size].size} productUnits={tickets} />
      </GameItemRightCornerBadgeWrapper>
    );
  }

  return null;
};
