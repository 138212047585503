import type { CSSObject } from '@emotion/react';
import { Color } from '@pafcloud/style';

export const backgroundStyle: CSSObject = {
  position: 'absolute',
  pointerEvents: 'none',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  overflow: 'hidden',
  background: Color.Layout.Background,
};
