import { Breakpoint } from '@pafcloud/style';
import type { AspectRatio, ColumnWidth } from './definitions';

type GridLayout = {
  columns: number;
  narrow: number;
  wide: number;
};

type GridBreakpoints = {
  default: GridLayout;
  [Breakpoint.TabletOrLarger]: GridLayout;
  [Breakpoint.LaptopOrLarger]: GridLayout;
  [Breakpoint.BigScreenOrLarger]: GridLayout;
  [Breakpoint.HDScreenOrLarger]: GridLayout;
};

export type GridBreakpoint = keyof GridBreakpoints;

const gridPortraitBreakpoints: GridBreakpoints = {
  default: {
    columns: 6,
    narrow: 2,
    wide: 3,
  },
  [Breakpoint.TabletOrLarger]: {
    columns: 12,
    narrow: 3,
    wide: 4,
  },
  [Breakpoint.LaptopOrLarger]: {
    columns: 12,
    narrow: 2,
    wide: 3,
  },
  [Breakpoint.BigScreenOrLarger]: {
    columns: 20,
    narrow: 2,
    wide: 4,
  },
  [Breakpoint.HDScreenOrLarger]: {
    columns: 20,
    narrow: 2,
    wide: 4,
  },
};

const gridLandscapeBreakpoints: GridBreakpoints = {
  default: {
    columns: 4,
    narrow: 3,
    wide: 4,
  },
  [Breakpoint.TabletOrLarger]: {
    columns: 6,
    narrow: 2,
    wide: 3,
  },
  [Breakpoint.LaptopOrLarger]: {
    columns: 18,
    narrow: 6,
    wide: 9,
  },
  [Breakpoint.BigScreenOrLarger]: {
    columns: 24,
    narrow: 6,
    wide: 8,
  },
  [Breakpoint.HDScreenOrLarger]: {
    columns: 30,
    narrow: 6,
    wide: 10,
  },
};

export const retinaMobileBreakpoint = '(pointer: coarse) and (-webkit-min-device-pixel-ratio: 1.8)';

export const getGridBreakpoint = (breakpoint: GridBreakpoint, aspectRatio: AspectRatio) => {
  const breakpoints = aspectRatio === 'landscape' ? gridLandscapeBreakpoints : gridPortraitBreakpoints;
  return breakpoints[breakpoint];
};

// With a fixed margin in pixels and a fluid width we have to guesstimate and closely matches the page margin
// We do this to exclude the margin from the desired size of the images.
const getPageMarginAdjustment = (breakpoint: GridBreakpoint) => {
  switch (breakpoint) {
    case Breakpoint.HDScreenOrLarger:
      return 1.2;
    case Breakpoint.BigScreenOrLarger:
      return 3.1;
    case Breakpoint.LaptopOrLarger:
      return 3;
    case Breakpoint.TabletOrLarger:
      return 3.25;
    case 'default':
      return 5;
  }
};

export const getImageSizes = (columnWidth: ColumnWidth, aspectRatio: AspectRatio) => {
  const breakpoints = [
    Breakpoint.HDScreenOrLarger,
    Breakpoint.BigScreenOrLarger,
    Breakpoint.LaptopOrLarger,
    Breakpoint.TabletOrLarger,
    'default',
  ] as const;

  const sizes = breakpoints.map((breakpoint) => {
    const PAGE_MARGIN_ADJUSTMENT = getPageMarginAdjustment(breakpoint);

    const grid = getGridBreakpoint(breakpoint, aspectRatio);
    const columnSpan = columnWidth === 'narrow' ? grid.narrow : grid.wide;

    const width = Math.floor((columnSpan / grid.columns) * 100) - PAGE_MARGIN_ADJUSTMENT;
    const condition = breakpoint.replace('@media ', '');

    if (breakpoint === 'default') {
      // To avoid loading x3 images on retina screens, we scale down requested width by 1.5x
      const RETINA_DISPLAY_DIVISOR = 1.5;
      const retinaMobileImageSize = `${retinaMobileBreakpoint} ${(width / RETINA_DISPLAY_DIVISOR).toFixed(2)}vw`;

      return `${retinaMobileImageSize}, ${width}vw`;
    }

    return `${condition} ${width}vw`;
  });

  return sizes.join(', ');
};

export const getPortraitImageSizes = (columnWidth: ColumnWidth) => {
  return getImageSizes(columnWidth, 'portrait');
};

export const getLandscapeImageSizes = (columnWidth: ColumnWidth) => {
  return getImageSizes(columnWidth, 'landscape');
};
