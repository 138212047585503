import type { FC } from 'react';
import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { lowercase } from '@pafcloud/util';
import { useTrackClickedGame, useTrackViewedGame } from '@pafcloud/contexts';
import { HorizontalScroller } from '@pafcloud/base-components';
import { Breakpoint } from '@pafcloud/style';
import type { BillboardGameList_block$key } from './__generated__/BillboardGameList_block.graphql';
import { Decorations } from './decorations/Decorations';
import {
  Cover,
  GameListHeader,
  GameListSection,
  Heading,
  Thumbnail,
  GameLink,
  SeeAllGamesLink,
  GameListItem,
} from './parts';
import { ShowTotalGamesCount } from './parts/ShowTotalGameCount';
import { getLandscapeImageSizes } from './grid';
import type { ColumnWidth } from './definitions';

const gameListBlockFragment = graphql`
  fragment BillboardGameList_block on BillboardGameListBlock {
    gameList {
      ...useTrackGame_gameList
      games(first: 20) {
        ...useTrackGame_games
        ...ShowTotalGameCount_games
        edges {
          node {
            ...GameLink_game
            ...Decorations_game
            name
            slug
            thumbnail(layout: LANDSCAPE)
          }
        }
      }
    }
    billboardSize
    title
    ...useTrackGame_trackable
    ...SeeAllGamesLink_block
    ...ShowTotalGameCount_block
  }
`;

const BillboardGameListScroll = styled(HorizontalScroller)<{ columnWidth: ColumnWidth }>(({ columnWidth }) => {
  if (columnWidth === 'wide') {
    return {
      '--number-of-items': 1,

      [Breakpoint.TabletOrLarger]: {
        '--number-of-items': 2,
      },
      [Breakpoint.LaptopOrLarger]: {
        '--number-of-items': 2,
      },
      [Breakpoint.BigScreenOrLarger]: {
        '--number-of-items': 3,
      },
      [Breakpoint.HDScreenOrLarger]: {
        '--number-of-items': 4,
      },
    };
  }
  return {
    '--number-of-items': 1.33,

    [Breakpoint.TabletOrLarger]: {
      '--number-of-items': 3,
    },
    [Breakpoint.LaptopOrLarger]: {
      '--number-of-items': 3,
    },
    [Breakpoint.BigScreenOrLarger]: {
      '--number-of-items': 4,
    },
    [Breakpoint.HDScreenOrLarger]: {
      '--number-of-items': 5,
    },
  };
});

type Props = {
  block: BillboardGameList_block$key;
};

export const BillboardGameList: FC<Props> = ({ block }) => {
  const blockData = useFragment(gameListBlockFragment, block);
  const billboardSize = lowercase(blockData.billboardSize);
  const imageSizes = useMemo(() => getLandscapeImageSizes(billboardSize), [billboardSize]);

  const onGameClicked = useTrackClickedGame({
    games: blockData.gameList.games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  const onGameViewed = useTrackViewedGame({
    games: blockData.gameList.games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  if (!blockData.gameList.games.edges.length) {
    return null;
  }

  return (
    <GameListSection aspectRatio="landscape" columnWidth={billboardSize}>
      <GameListHeader>
        <Heading title={blockData.title} />
        <SeeAllGamesLink block={blockData} />
        <ShowTotalGamesCount block={blockData} games={blockData.gameList.games} />
      </GameListHeader>

      <BillboardGameListScroll columnWidth={billboardSize}>
        {blockData.gameList.games.edges.map(({ node: game }, index) => (
          <GameListItem key={game.slug}>
            <GameLink game={game} onClick={onGameClicked} index={index} onView={onGameViewed}>
              <Cover>
                <Thumbnail gameName={game.name} thumbnail={game.thumbnail} sizes={imageSizes} />
                <Decorations game={game} />
              </Cover>
            </GameLink>
          </GameListItem>
        ))}
      </BillboardGameListScroll>
    </GameListSection>
  );
};
