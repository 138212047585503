import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import type { CSSObject } from '@emotion/react';
import { InternalLink } from '@pafcloud/base-components';
import { Font, FontTextSize, NoMotionPreference } from '@pafcloud/style';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { FlexibleGameListColors } from '../flexible-game-list-colors';
import { PartsZIndex } from '../definitions';
import type { SeeAllGamesLink_block$key } from './__generated__/SeeAllGamesLink_block.graphql';

const linkFragment = graphql`
  fragment SeeAllGamesLink_block on CommonGameListBlock {
    link {
      text
      url
      ariaLabel
    }
  }
`;

const getThemeStyle = (): CSSObject => {
  if ($buildEnv.theme === 'classic') {
    return {
      transform: 'translateY(0.25em)', // Fix Adelle font baseline
    };
  }

  if ($buildEnv.theme === 'momentum') {
    return {
      fontWeight: 900,
      fontStyle: 'italic',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    };
  }

  return {};
};

const SeeAllLink = styled(InternalLink)({
  position: 'relative',
  zIndex: PartsZIndex.HeaderLink,
  display: 'flex',
  justifyContent: 'center',
  fontFamily: Font.Body,
  fontSize: FontTextSize.Normal,
  lineHeight: 1,
  color: FlexibleGameListColors.HeaderLink,
  outline: 'transparent',

  '::before': {
    content: '""',
    position: 'absolute',
    bottom: -4,
    width: 0,
    height: 2,
    background: 'currentColor',

    [NoMotionPreference]: {
      transition: 'width 0.2s ease-in-out',
    },
  },

  '&:focus-visible::before, &:hover::before': {
    width: 'calc(100% + 2px)',
  },

  ...getThemeStyle(),
});

type SeeAllGamesLinkProps = {
  block: SeeAllGamesLink_block$key;
};

export const SeeAllGamesLink: FC<SeeAllGamesLinkProps> = ({ block }) => {
  const { link } = useFragment(linkFragment, block);

  if (link == null) {
    return null;
  }

  return (
    <SeeAllLink href={link.url} aria-label={link.ariaLabel ?? undefined}>
      {link.text}
    </SeeAllLink>
  );
};
