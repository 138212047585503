/**
 * @generated SignedSource<<2f31d9040958bfbc2052bc801362ce7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShowTotalGameCount_games$data = {
  readonly totalCount: number | null;
  readonly " $fragmentType": "ShowTotalGameCount_games";
};
export type ShowTotalGameCount_games$key = {
  readonly " $data"?: ShowTotalGameCount_games$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShowTotalGameCount_games">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowTotalGameCount_games",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "type": "GameListGames_Connection",
  "abstractKey": null
};

(node as any).hash = "25dd07adf4f7e101b29844b3c57f0cc6";

export default node;
