import type { RefObject } from 'react';
import { useEffect } from 'react';
import { useHandler } from './useHandler';

export function useResizeObserver<T extends Element>(ref: RefObject<T | undefined>, onResize: () => void) {
  const handleResize = useHandler(onResize);

  useEffect(() => {
    const element = ref.current;

    if (element == null || typeof ResizeObserver === 'undefined') {
      return;
    }

    const resizeObserverInstance = new ResizeObserver((entries) => {
      if (entries.length) {
        handleResize();
      }
    });

    resizeObserverInstance.observe(element);

    return () => {
      if (element) {
        resizeObserverInstance.unobserve(element);
      }
    };
  }, [ref, handleResize]);
}
