import type { FC } from 'react';
import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { useTrackClickedGame, useTrackViewedGame } from '@pafcloud/contexts';
import { HorizontalScroller } from '@pafcloud/base-components';
import { Breakpoint } from '@pafcloud/style';
import type { GameplayGameList_block$key } from './__generated__/GameplayGameList_block.graphql';
import { Decorations } from './decorations/Decorations';
import {
  Cover,
  GameInfo,
  GameListHeader,
  GameListSection,
  GameLogo,
  Heading,
  Thumbnail,
  GameLink,
  GameListItem,
} from './parts';
import { SeeAllGamesLink } from './parts/SeeAllGamesLink';
import { ShowTotalGamesCount } from './parts/ShowTotalGameCount';
import { getLandscapeImageSizes } from './grid';

const gameListBlockFragment = graphql`
  fragment GameplayGameList_block on GameplayGameListBlock {
    gameList {
      ...useTrackGame_gameList
      games(first: 20) {
        ...useTrackGame_games
        ...ShowTotalGameCount_games
        edges {
          node {
            ...GameLink_game
            ...Decorations_game
            ...GameLogo_game
            name
            supplier
            slug
            thumbnail(layout: LANDSCAPE, type: INGAME)
          }
        }
      }
    }
    title
    ...useTrackGame_trackable
    ...SeeAllGamesLink_block
    ...ShowTotalGameCount_block
  }
`;

const GameplayGameListScroll = styled(HorizontalScroller)({
  '--number-of-items': 1.33,

  [Breakpoint.TabletOrLarger]: {
    '--number-of-items': 3,
  },
  [Breakpoint.LaptopOrLarger]: {
    '--number-of-items': 4,
  },
  [Breakpoint.BigScreenOrLarger]: {
    '--number-of-items': 5,
  },
});

type Props = {
  block: GameplayGameList_block$key;
};

export const GameplayGameList: FC<Props> = ({ block }) => {
  const blockData = useFragment(gameListBlockFragment, block);
  const imageSizes = useMemo(() => getLandscapeImageSizes('narrow'), []);

  const onGameClicked = useTrackClickedGame({
    games: blockData.gameList.games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  const onGameViewed = useTrackViewedGame({
    games: blockData.gameList.games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  if (!blockData.gameList.games.edges.length) {
    return null;
  }

  return (
    <GameListSection aspectRatio="landscape" columnWidth="narrow">
      <GameListHeader>
        <Heading title={blockData.title} />
        <SeeAllGamesLink block={blockData} />
        <ShowTotalGamesCount block={blockData} games={blockData.gameList.games} />
      </GameListHeader>

      <GameplayGameListScroll>
        {blockData.gameList.games.edges.map(({ node: game }, index) => (
          <GameListItem key={game.slug}>
            <GameLink game={game} onClick={onGameClicked} index={index} onView={onGameViewed}>
              <Cover>
                <Thumbnail gameName={game.name} thumbnail={game.thumbnail} sizes={imageSizes} />
                <Decorations game={game} />
                <GameLogo game={game} />
              </Cover>
            </GameLink>
            <GameInfo name={game.name} supplier={game.supplier} />
          </GameListItem>
        ))}
      </GameplayGameListScroll>
    </GameListSection>
  );
};
