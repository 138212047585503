/**
 * @generated SignedSource<<172464e859290d049be6312bee77786c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterGridGameList_gameList$data = {
  readonly games: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly slug: string;
        readonly supplier: string;
        readonly thumbnail: string;
        readonly " $fragmentSpreads": FragmentRefs<"Decorations_game" | "GameLink_game" | "GameLogo_game">;
      };
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"useTrackGame_games">;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"useTrackGame_gameList">;
  readonly " $fragmentType": "FilterGridGameList_gameList";
};
export type FilterGridGameList_gameList$key = {
  readonly " $data"?: FilterGridGameList_gameList$data;
  readonly " $fragmentSpreads": FragmentRefs<"FilterGridGameList_gameList">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "games"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 40,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tag"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./PaginatedFilterGridGameListQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "FilterGridGameList_gameList",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useTrackGame_gameList",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gameListId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internalName",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": "games",
      "args": [
        {
          "kind": "Variable",
          "name": "tag",
          "variableName": "tag"
        }
      ],
      "concreteType": "GameListGames_Connection",
      "kind": "LinkedField",
      "name": "__PaginatedFilterGridGameList_GameList_games_connection",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useTrackGame_games",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recommendationId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recommendationGroupId",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GameEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Game",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "GameLink_game"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Decorations_game"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "GameLogo_game"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "supplier",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "layout",
                      "value": "PORTRAIT"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "thumbnail",
                  "storageKey": "thumbnail(layout:\"PORTRAIT\")"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "GameList",
  "abstractKey": null
};
})();

(node as any).hash = "c005169762f65fef4f37d26f2618144f";

export default node;
