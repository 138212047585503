import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { withBuildEnv } from '../../utils';
import { Background as Background_default } from './fullWidthBackground.default';
import { Background as Background_golden } from './fullWidthBackground.golden';
import { Background as Background_dreams } from './fullWidthBackground.dreams';
import { Background as Background_goldenV2 } from './fullWidthBackground.goldenV2';

export const FullWidthBackground = withBuildEnv(() => {
  if ($buildEnv.theme === 'golden') {
    return Background_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return Background_goldenV2;
  }
  if ($buildEnv.theme === 'dreams') {
    return Background_dreams;
  }

  return Background_default;
});
