import { Color } from '@pafcloud/style';

export const Colors = {
  // Cover gradient
  CoverGradient: `
  linear-gradient(0deg, rgba(0,0,0,0.7), rgba(0,0,0,0.5) 25%, transparent 50%, transparent)`,

  // Turnover
  TurnoverBorder: `solid 3px ${Color.General.Bonus}`,
  TurnoverShadow: `0 0 8px 0px ${Color.General.Bonus}`,
  TurnoverBadgeBackground: Color.General.Bonus,
  TurnoverBadgeText: Color.General.BonusContrast,
  TurnoverBadgeTextShadow: Color.Elevation.Level1,

  // Maintenance badge
  MaintenanceText: '#ffffff',
  MaintenanceOverlay: Color.Surface.Disabled.Background,
  MaintenanceOverlayFilter: 'grayscale(0.9) brightness(60%) contrast(110%)',

  // Info badge
  InfoBadgeText: Color.Primitive.GradientContrast,
  InfoBadgeTextShadow: `0 1px 0 rgba(0,0,0,0.1)`,
  InfoBadgeBackground: Color.Primitive.Gradient,
  InfoBadgeShadow: '0 1px 0 0 rgba(0, 0, 0, 0.25)',
  InfoBadgeRadius: '99vw',

  // Game Item
  GameItemShadow: Color.Elevation.Level1,
  GameItemShadowHover: Color.Elevation.Level3,
  GameItemFocus: `solid 4px ${Color.Primitive.Primary}`,
  GameItemBorderRadius: Color.CornerRadius.Base,

  // Game info
  GameInfoNameText: Color.TextColors.BodyText,
  GameInfoExtraInfoText: Color.TextColors.MutedText,

  // List header
  HeaderText: Color.TextColors.HeadingText,
  HeaderLink: Color.TextColors.HeadingText,

  // Next/Prev buttons
  NavButtonBackground: `
  linear-gradient(var(--gradient-direction), ${Color.Layout.Background}00, ${Color.Layout.Background}c2),
  linear-gradient(var(--gradient-direction), ${Color.Layout.Background}00, ${Color.Layout.Background}55 20%, ${Color.Layout.Background}c2)
  `,
  NavButtonIcon: Color.TextColors.BodyText,
  NavButtonHoverShadow: `drop-shadow(0 0px 5px ${Color.Layout.Background})`,

  BackgroundOpacity: 1,

  HighlightGameListFrameBorder: Color.Surface.Base.Background,
  HighlightGameListHeaderText: Color.Surface.Base.Background,

  NumberedGameListText: Color.TextColors.BodyText,
  NumberedGameListTextShadow: undefined,
  NumberedGameListInfoBackground: `linear-gradient(120deg, ${Color.Surface.Base.Background}, ${Color.Surface.Base.Background}, ${Color.Primitive.Accent}20)`,
  NumberedGameListCounterText: `linear-gradient(230deg, ${Color.Primitive.Accent}20, ${Color.Primitive.AccentTint}80)`,
} as const;
