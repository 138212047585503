import type { FC } from 'react';
import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { useTrackClickedGame, useTrackViewedGame } from '@pafcloud/contexts';
import { HorizontalScroller } from '@pafcloud/base-components';
import { Breakpoint } from '@pafcloud/style';
import type { PosterGameList_block$key } from './__generated__/PosterGameList_block.graphql';
import { Decorations } from './decorations/Decorations';
import {
  Cover,
  GameListHeader,
  GameListSection,
  Heading,
  Thumbnail,
  GameLink,
  SeeAllGamesLink,
  GameListItem,
} from './parts';
import { ShowTotalGamesCount } from './parts/ShowTotalGameCount';
import { getPortraitImageSizes } from './grid';
import type { ColumnWidth } from './definitions';

const gameListBlockFragment = graphql`
  fragment PosterGameList_block on PosterGameListBlock {
    gameList {
      ...useTrackGame_gameList
      games(first: 20) {
        ...useTrackGame_games
        ...ShowTotalGameCount_games
        edges {
          node {
            ...GameLink_game
            ...Decorations_game
            name
            slug
            thumbnail(layout: PORTRAIT)
          }
        }
      }
    }
    title
    ...useTrackGame_trackable
    ...SeeAllGamesLink_block
    ...ShowTotalGameCount_block
    featured
  }
`;

const PosterGameListScroll = styled(HorizontalScroller)<{ columnWidth: ColumnWidth }>(({ columnWidth }) => {
  if (columnWidth === 'wide') {
    return {
      '--number-of-items': 2,

      [Breakpoint.TabletOrLarger]: {
        '--number-of-items': 3,
      },
      [Breakpoint.LaptopOrLarger]: {
        '--number-of-items': 4,
      },
      [Breakpoint.BigScreenOrLarger]: {
        '--number-of-items': 5,
      },
    };
  }
  return {
    '--number-of-items': 3,

    [Breakpoint.TabletOrLarger]: {
      '--number-of-items': 4,
    },
    [Breakpoint.LaptopOrLarger]: {
      '--number-of-items': 6,
    },
    [Breakpoint.BigScreenOrLarger]: {
      '--number-of-items': 10,
    },
  };
});

type Props = {
  block: PosterGameList_block$key;
};

export const PosterGameList: FC<Props> = ({ block }) => {
  const blockData = useFragment(gameListBlockFragment, block);
  const columnWidth = blockData.featured ? 'wide' : 'narrow';
  const imageSizes = useMemo(() => getPortraitImageSizes(columnWidth), [columnWidth]);

  const onGameClicked = useTrackClickedGame({
    games: blockData.gameList.games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  const onGameViewed = useTrackViewedGame({
    games: blockData.gameList.games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  if (!blockData.gameList.games.edges.length) {
    return null;
  }

  return (
    <GameListSection aspectRatio="portrait" columnWidth={columnWidth}>
      <GameListHeader>
        <Heading title={blockData.title} />
        <SeeAllGamesLink block={blockData} />
        <ShowTotalGamesCount block={blockData} games={blockData.gameList.games} />
      </GameListHeader>

      <PosterGameListScroll columnWidth={columnWidth}>
        {blockData.gameList.games.edges.map(({ node: game }, index) => (
          <GameListItem key={game.slug}>
            <GameLink game={game} onClick={onGameClicked} index={index} onView={onGameViewed}>
              <Cover>
                <Thumbnail gameName={game.name} thumbnail={game.thumbnail} sizes={imageSizes} />
                <Decorations game={game} />
              </Cover>
            </GameLink>
          </GameListItem>
        ))}
      </PosterGameListScroll>
    </GameListSection>
  );
};
