import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { FullWidthBackground, FullWidthImage, Video } from '@pafcloud/base-components';
import { useIsSSR, useMedia } from '@pafcloud/react-hook-utils';
import { Breakpoint } from '@pafcloud/style';
import { FlexibleGameListColors } from '../flexible-game-list-colors';
import type { GameListBackground$key } from './__generated__/GameListBackground.graphql';

const gameListBackgroundFragment = graphql`
  fragment GameListBackground on GameListBackground {
    imageDesktop {
      url
    }
    imageMobile {
      url
    }
    videoDesktop {
      url
    }
    videoMobile {
      url
    }
    isFte
  }
`;

const FullWidthVideo = styled(Video)({
  width: '100%',
  height: '101%', //101% prevents sub-pixels gap between background and video
  objectFit: 'cover',
  opacity: FlexibleGameListColors.BackgroundOpacity,
});

const FullWidthImageOpacity = styled(FullWidthImage)({
  opacity: FlexibleGameListColors.BackgroundOpacity,
});

type VideoBackgroundProps = {
  videoUrl?: string | null;
  isFte: boolean;
};

const VideoBackground: FC<VideoBackgroundProps> = ({ videoUrl, isFte }) => {
  const isSSR = useIsSSR();
  const isPhone = useMedia(Breakpoint.Phone);
  const noMotion = useMedia('(prefers-reduced-motion: reduce)', false);

  if (isSSR || videoUrl == null) {
    return null;
  }

  if (isFte) {
    return <FullWidthVideo src={videoUrl} autoPlay={false} muted loop playsInline data-testid="background-video-fte" />;
  }

  if (isPhone) {
    return (
      <FullWidthVideo src={videoUrl} autoPlay={!noMotion} muted loop playsInline data-testid="background-video-phone" />
    );
  }
  return (
    <FullWidthVideo src={videoUrl} autoPlay={!noMotion} muted loop playsInline data-testid="background-video-desktop" />
  );
};

type Props = {
  block: GameListBackground$key | null;
};

export const GameListBackground: FC<Props> = ({ block }) => {
  const gameListBackground = useFragment(gameListBackgroundFragment, block);

  const isPhone = useMedia(Breakpoint.Phone);

  if (gameListBackground == null) {
    return null;
  }

  const { imageDesktop, imageMobile, videoDesktop, videoMobile, isFte } = gameListBackground;

  if (isPhone) {
    if (videoMobile != null) {
      return (
        <FullWidthBackground>
          <VideoBackground videoUrl={videoMobile.url} isFte={isFte} />
        </FullWidthBackground>
      );
    }
    if (imageMobile != null) {
      return (
        <FullWidthBackground data-testid="background-image-phone">
          <FullWidthImageOpacity mobileImageUrl={imageMobile.url} desktopImageUrl="" />
        </FullWidthBackground>
      );
    }
  }

  if (videoDesktop != null) {
    return (
      <FullWidthBackground>
        <VideoBackground videoUrl={videoDesktop.url} isFte={isFte} />
      </FullWidthBackground>
    );
  }

  return (
    <FullWidthBackground data-testid="background-image-desktop">
      <FullWidthImageOpacity mobileImageUrl={imageDesktop?.url ?? ''} desktopImageUrl={imageDesktop?.url ?? ''} />
    </FullWidthBackground>
  );
};
