/**
 * @generated SignedSource<<50c8d9136bca7b39afbe21e4564a0b3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GameListBackground$data = {
  readonly imageDesktop: {
    readonly url: string;
  } | null;
  readonly imageMobile: {
    readonly url: string;
  } | null;
  readonly isFte: boolean;
  readonly videoDesktop: {
    readonly url: string | null;
  } | null;
  readonly videoMobile: {
    readonly url: string | null;
  } | null;
  readonly " $fragmentType": "GameListBackground";
};
export type GameListBackground$key = {
  readonly " $data"?: GameListBackground$data;
  readonly " $fragmentSpreads": FragmentRefs<"GameListBackground">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GameListBackground",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "imageDesktop",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "imageMobile",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GameListBackgroundVideo",
      "kind": "LinkedField",
      "name": "videoDesktop",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GameListBackgroundVideo",
      "kind": "LinkedField",
      "name": "videoMobile",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFte",
      "storageKey": null
    }
  ],
  "type": "GameListBackground",
  "abstractKey": null
};
})();

(node as any).hash = "8194cec42368f65c2ce44df1b8321f32";

export default node;
