import styled from '@emotion/styled';

import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { useTranslation } from '@pafcloud/i18n';
import { Icon } from '@pafcloud/base-components';
import { useMatchGameToBingoTickets, useMatchGameToFreespins, useMatchGameToPlayAndGet } from '@pafcloud/contexts';
import { FlexibleGameListColors } from '../flexible-game-list-colors';
import type { GameBadge_game$key } from './__generated__/GameBadge_game.graphql';

const gameBadgeFragment = graphql`
  fragment GameBadge_game on Game {
    new
    familyName
  }
`;

const InfoBadge = styled.div({
  position: 'absolute',
  left: 10,
  top: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 40,
  padding: '0.25em 0.5em',
  borderRadius: FlexibleGameListColors.InfoBadgeRadius,
  background: FlexibleGameListColors.InfoBadgeBackground,
  boxShadow: FlexibleGameListColors.InfoBadgeShadow,

  fontFamily: 'sans-serif',
  fontSize: 'clamp(10px, 1.5vw, 12px)',
  fontWeight: 'bold',

  color: FlexibleGameListColors.InfoBadgeText,
  textAlign: 'center',
  textShadow: FlexibleGameListColors.InfoBadgeTextShadow,
  textTransform: 'uppercase',
});

const GameBadgeIcon = styled(Icon)({
  position: 'relative',
  top: '-0.01em',
  marginRight: '0.25em',
});

type GameBadgeProps = {
  game: GameBadge_game$key;
};

export const GameBadge: FC<GameBadgeProps> = (props) => {
  const { t } = useTranslation(['common', 'game-list']);
  const game = useFragment(gameBadgeFragment, props.game);

  const matchGameToBingoTickets = useMatchGameToBingoTickets(game.familyName);
  const showBingoTickets = !!matchGameToBingoTickets;
  const matchGameToFreespins = useMatchGameToFreespins(game.familyName);
  const showFreespins = !!matchGameToFreespins;

  const matchGameToPlayAndGet = useMatchGameToPlayAndGet(game.familyName);
  const showPlayAndGetRounds = !!matchGameToPlayAndGet;

  if (showFreespins) {
    return (
      <InfoBadge title={t('game-list:badge.title.freespins', { numberOfRounds: matchGameToFreespins?.spinsRemaining })}>
        <GameBadgeIcon name="gift" size="1em" />
        {matchGameToFreespins?.spinsRemaining}
      </InfoBadge>
    );
  }

  if (showBingoTickets) {
    return (
      <InfoBadge
        title={t('game-list:badge.title.bingo-tickets', { numberOfTickets: matchGameToBingoTickets?.ticketsRemaining })}
      >
        <GameBadgeIcon name="gift" size="1em" />
        {matchGameToBingoTickets?.ticketsRemaining}
      </InfoBadge>
    );
  }

  if (showPlayAndGetRounds) {
    if (matchGameToPlayAndGet.playAndGetType === 'PlayRealMoneyOfferStep') {
      return (
        <InfoBadge title={t('game-list:badge.title.play-and-get.play-real-money')}>
          <GameBadgeIcon name="coins" size="1em" />
          {matchGameToPlayAndGet?.remaining}
        </InfoBadge>
      );
    }

    return (
      <InfoBadge title={t('game-list:badge.title.play-and-get.play-real-rounds')}>
        <GameBadgeIcon name="casinoLive" size="1em" />
        {matchGameToPlayAndGet?.remaining}
      </InfoBadge>
    );
  }

  if (game.new) {
    return <InfoBadge title={t('game-list:badge.title.new')}>{t('new')}</InfoBadge>;
  }

  return null;
};
