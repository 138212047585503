import styled from '@emotion/styled';
import type { FC } from 'react';
import { Heading as DefaultHeading, PrimaryHeading } from '@pafcloud/base-components';

export const FlexibleGameListTitle = styled(DefaultHeading)(PrimaryHeading);

type Props = {
  title?: string | null;
};

export const Heading: FC<Props> = ({ title }) => {
  if (!title) {
    return null;
  }

  return <FlexibleGameListTitle>{title}</FlexibleGameListTitle>;
};
