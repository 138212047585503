import type { OperationType } from 'relay-runtime';
import type { KeyType, KeyTypeData } from 'react-relay/relay-hooks/helpers';
import type { usePaginationFragmentHookType } from 'react-relay/relay-hooks/usePaginationFragment';

const PAGINATION_INCREMENT = 3;

type PaginationResult = usePaginationFragmentHookType<OperationType, KeyType, KeyTypeData<KeyType>>;

type Options<T> = {
  edges: readonly T[];
  rows: number;
  columns: number;
  setRows: (rows: number) => void;
  onError?: (error: Error) => void;
};

export const getPaginatedGrid = <T>(pagination: PaginationResult, options: Options<T>) => {
  const { edges, columns, rows, setRows, onError } = options;
  const { hasNext, loadNext, isLoadingNext } = pagination;
  const currentCount = rows * columns;

  const loadMore = () => {
    const loadAmount = columns * PAGINATION_INCREMENT;
    const maxNumberToShow = currentCount + loadAmount;
    const quantizedNumber = Math.floor(maxNumberToShow / columns) * columns;

    const hasLoadedRequired = !hasNext || quantizedNumber <= edges.length;
    if (hasLoadedRequired) {
      setRows(rows + PAGINATION_INCREMENT);
      return;
    }

    if (hasNext && !isLoadingNext) {
      loadNext(loadAmount, {
        onComplete: (error: Error | null) => {
          if (error != null) {
            onError?.(error);
            return;
          }

          setRows(rows + PAGINATION_INCREMENT);
        },
      });
    }
  };

  return {
    data: edges.slice(0, currentCount),
    hasNext: hasNext || currentCount < edges.length,
    isLoadingNext,
    loadNext: loadMore,
  };
};
