import styled from '@emotion/styled';
import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Image } from '@pafcloud/base-components';
import { PartsZIndex } from '../definitions';
import { FlexibleGameListColors } from '../flexible-game-list-colors';
import { FullCover } from '../decorations/shared';
import type { GameLogo_game$key } from './__generated__/GameLogo_game.graphql';

const gameLogoFragment = graphql`
  fragment GameLogo_game on Game {
    logo
  }
`;

const LogoOverlay = styled(FullCover)({
  zIndex: PartsZIndex.GameLogo,
  background: FlexibleGameListColors.CoverGradient,
});

const Logo = styled(Image)({
  position: 'absolute',
  bottom: 8,
  left: 8,
  maxWidth: '50%',
  maxHeight: '66%',

  '[data-aspect-ratio="landscape"] &': {
    maxHeight: '50%',
  },

  objectFit: 'contain',
  objectPosition: 'left bottom',

  filter: 'drop-shadow(0 0 6px rgba(0, 0, 0, 1))',
});

type GameLogoProps = {
  game: GameLogo_game$key;
};

export const GameLogo: FC<GameLogoProps> = (props) => {
  const game = useFragment(gameLogoFragment, props.game);

  if (!game?.logo) {
    return null;
  }

  return (
    <LogoOverlay>
      <Logo
        src={game.logo}
        width={256}
        height={256}
        sizes="
          (max-width: 500px) 20vw,
          (max-width: 811px) 14vw,
          (max-width: 1366px) 14vw,
          (max-width: 1920px) 10vw, 256px"
      />
    </LogoOverlay>
  );
};
