import styled from '@emotion/styled';
import { Breakpoint } from '@pafcloud/style';

export const GameListHeader = styled.header({
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  alignItems: 'flex-end',
  padding: '0 var(--full-width-margin) 12px',

  '&:empty': {
    display: 'none',
  },

  [Breakpoint.LaptopOrLarger]: {
    paddingBottom: 16,
  },
});
