import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import type { Decorations_game$key } from './__generated__/Decorations_game.graphql';
import { MonetaryDetails } from './MonetaryDetails';
import { GameBadge } from './GameBadge';
import { TurnoverBonus } from './Turnover';

const decorationsFragment = graphql`
  fragment Decorations_game on Game {
    ...MonetaryDetails_game
    ...GameBadge_game
    ...TurnoverBonus_game
  }
`;

type DecorationsProps = {
  game: Decorations_game$key;
};

export const Decorations: FC<DecorationsProps> = (props) => {
  const game = useFragment(decorationsFragment, props.game);

  return (
    <>
      <TurnoverBonus game={game} />
      <GameBadge game={game} />
      <MonetaryDetails game={game} />
    </>
  );
};
