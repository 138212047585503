import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Breakpoint } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { useFormatAmount, useFormatScreenReaderAmount, useFormatAmountRange } from '@pafcloud/react-hook-utils';
import { getCurrencyCode } from '@pafcloud/locale';
import { PartsZIndex } from '../definitions';
import type { MonetaryDetails_game$key } from './__generated__/MonetaryDetails_game.graphql';
import { FullCover } from './shared';

const monetaryDetailsFragment = graphql`
  fragment MonetaryDetails_game on Game {
    jackpot {
      amount
    }
    betInfo {
      minBet
      maxBet
    }
  }
`;

export const MonetaryDetailsWrapper = styled(FullCover)({
  zIndex: PartsZIndex.MonetaryDetails,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  padding: 8,

  color: '#ffffff',
  textAlign: 'center',

  // PML has its sidebar visible so we have to use a smaller vw value
  '--vpw': $buildEnv.theme === 'golden' || $buildEnv.theme === 'quirky' ? '8.5vw' : '10vw',
  '--relative-font-size': 'calc((var(--vpw) / var(--factor)))',
  '--max-font-size': '2.1rem',
  '--screen-scale': '2',
  fontSize: 'min(var(--relative-font-size), var(--max-font-size))',

  [Breakpoint.TabletOrLarger]: {
    '--screen-scale': '3.2',
  },
  [Breakpoint.LaptopOrLarger]: {
    '--screen-scale': '6',
  },
  [Breakpoint.BigScreenOrLarger]: {
    '--screen-scale': '10',
  },
  [Breakpoint.HDScreenOrLarger]: {
    '--screen-scale': '9',
  },

  '--factor': 'var(--screen-scale)',

  '[data-column-width="narrow"] &': {
    '--factor': 'calc(var(--screen-scale) + 1)',
  },
});

const Jackpot = styled.span({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3px 0.5em',

  backdropFilter: 'blur(12px) brightness(80%) saturate(120%) contrast(102%)',
  background: 'rgba(42,42,42,0.0)',
  borderRadius: '99vw',

  fontSize: '0.85em',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  textShadow: 'none',
});

const BetInfo = styled.span({
  marginTop: 2,
  fontSize: '10px',
  fontWeight: 'bold',
  opacity: 0.9,

  '::after': {
    content: '""',
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1,
    height: 'calc(var(--radius) * 2)',
    borderBottomLeftRadius: 'var(--radius)',
    borderBottomRightRadius: 'var(--radius)',
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%)',
  },
});

const MinMaxBet: FC<{ minBet: number; maxBet: number }> = ({ minBet, maxBet }) => {
  const { t } = useTranslation('game-list');
  const formatBet = useFormatAmountRange();

  return <BetInfo title={t('monetary.title.bet-info')}>{formatBet(minBet, maxBet)}</BetInfo>;
};

export const JackpotValue: FC<{ amount: number }> = ({ amount }) => {
  const { t } = useTranslation('game-list');
  const formatAmount = useFormatAmount({ decimals: false });
  const formatScreenReaderAmount = useFormatScreenReaderAmount();

  return (
    <Jackpot
      title={t('monetary.title.jackpot')}
      aria-label={t('monetary.title.jackpot') + ': ' + formatScreenReaderAmount(amount, getCurrencyCode())}
    >
      {formatAmount(amount, { currencyCode: getCurrencyCode() })}
    </Jackpot>
  );
};

type MonetaryDetailsProps = {
  game: MonetaryDetails_game$key;
};

export const MonetaryDetails: FC<MonetaryDetailsProps> = (props) => {
  const game = useFragment(monetaryDetailsFragment, props.game);

  // TODO: Move betinfo check to bff.
  // Make sure it is visible on spain and switzerland
  const showBetInfo = game.betInfo != null && ($buildEnv.market === 'spain' || $buildEnv.market === 'switzerland');
  const showJackpot = game.jackpot != null && game.jackpot.amount > 0;

  if (!showJackpot && !showBetInfo) {
    return null;
  }

  return (
    <MonetaryDetailsWrapper>
      {showJackpot && <JackpotValue amount={game.jackpot.amount} />}
      {showBetInfo && <MinMaxBet maxBet={game.betInfo.maxBet} minBet={game.betInfo.minBet} />}
    </MonetaryDetailsWrapper>
  );
};
