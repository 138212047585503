import styled from '@emotion/styled';
import type { FC } from 'react';
import { Image } from '@pafcloud/base-components';

const Figure = styled.figure({
  position: 'relative',
  margin: 0,
  padding: 0,

  aspectRatio: 'var(--aspect-ratio)',

  // iOS 14 does not support aspect-ratio
  '@supports not (aspect-ratio: 1/1)': {
    '::before': {
      content: '""',
      float: 'left',
      height: 0,
      paddingTop: 'var(--aspect-ratio-percentage)',
    },
    '::after': {
      content: '""',
      display: 'table',
      clear: 'both',
    },
  },

  img: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    aspectRatio: 'var(--aspect-ratio)',
    display: 'block',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '100%',
    borderRadius: 'var(--radius)',
  },
});

const GameName = styled.figcaption({
  clip: 'rect(1px, 1px, 1px, 1px)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: 1,
  margin: '-1px',
});

type Props = {
  thumbnail: string;
  gameName: string;
  loading?: 'eager' | 'lazy';
  sizes?: string;
  className?: string;
};

export const Thumbnail: FC<Props> = ({
  thumbnail,
  gameName: altText,
  loading = 'lazy',
  sizes = '100vw',
  className,
}) => {
  return (
    <Figure className={className}>
      <Image src={thumbnail} alt="" fill={true} sizes={sizes} loading={loading} />
      <GameName aria-hidden="true">{altText}</GameName>
    </Figure>
  );
};
