import styled from '@emotion/styled';
import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { useTrackClickedGame, useTrackViewedGame } from '@pafcloud/contexts';
import { Breakpoint } from '@pafcloud/style';
import type { MosaicGameList_block$key } from './__generated__/MosaicGameList_block.graphql';
import { Cover, GameListHeader, Heading, Thumbnail, GameLink, SeeAllGamesLink, GameList } from './parts';
import { Decorations } from './decorations/Decorations';
import { ShowTotalGamesCount } from './parts/ShowTotalGameCount';
import { AspectRatioValues } from './definitions';

const gameListBlockFragment = graphql`
  fragment MosaicGameList_block on MosaicGameListBlock {
    gameList {
      ...useTrackGame_gameList
      games(first: 5) {
        ...useTrackGame_games
        ...ShowTotalGameCount_games
        edges {
          node {
            ...GameLink_game
            ...Decorations_game
            name
            slug
            thumbnailLandscape: thumbnail(layout: LANDSCAPE)
            thumbnailPortrait: thumbnail(layout: PORTRAIT)
          }
        }
      }
    }
    title
    ...useTrackGame_trackable
    ...SeeAllGamesLink_block
    ...ShowTotalGameCount_block
  }
`;

const MOSAIC_BREAKPOINT = Breakpoint.TabletOrLarger;

const MosaicSection = styled(GameList)({
  '--aspect-ratio': AspectRatioValues.portrait.ratio,
  '--aspect-ratio-percentage': AspectRatioValues.portrait.percentage,

  [MOSAIC_BREAKPOINT]: {
    '--aspect-ratio': AspectRatioValues.landscape.ratio,
    '--aspect-ratio-percentage': AspectRatioValues.landscape.percentage,
  },
});

const GridMosaic = styled.ul({
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gap: 'var(--gap)',
  margin: '0 var(--full-width-margin)',
  padding: 0,
  listStyle: 'none',
});

const Item = styled.li({
  gridRow: 'span 2',
  gridColumn: 'span 2',

  [`&:nth-of-type(-n + 2)`]: {
    gridColumn: 'span 3',
  },
});

const DesktopThumbnail = styled(Thumbnail)({
  display: 'none',

  [MOSAIC_BREAKPOINT]: {
    display: 'flex',
  },
});

const MobileThumbnail = styled(Thumbnail)({
  display: 'flex',

  [MOSAIC_BREAKPOINT]: {
    display: 'none',
  },
});

type Props = {
  block: MosaicGameList_block$key;
};

export const MosaicGameList: FC<Props> = ({ block }) => {
  const blockData = useFragment(gameListBlockFragment, block);

  const onGameClicked = useTrackClickedGame({
    games: blockData.gameList.games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  const onGameViewed = useTrackViewedGame({
    games: blockData.gameList.games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  if (!blockData.gameList.games.edges.length) {
    return null;
  }

  return (
    <MosaicSection data-column-width="narrow">
      <GameListHeader>
        <Heading title={blockData.title} />
        <SeeAllGamesLink block={blockData} />
        <ShowTotalGamesCount block={blockData} games={blockData.gameList.games} />
      </GameListHeader>

      <GridMosaic>
        {blockData.gameList.games.edges.map(({ node: game }, index) => {
          const imageSize = index <= 1 ? '50vw' : '33vw';
          return (
            <Item key={game.slug}>
              <GameLink game={game} onClick={onGameClicked} index={index} onView={onGameViewed}>
                <Cover>
                  <DesktopThumbnail gameName={game.name} thumbnail={game.thumbnailLandscape} sizes={imageSize} />
                  <MobileThumbnail gameName={game.name} thumbnail={game.thumbnailPortrait} sizes={imageSize} />
                  <Decorations game={game} />
                </Cover>
              </GameLink>
            </Item>
          );
        })}
      </GridMosaic>
    </MosaicSection>
  );
};
