import type { FC } from 'react';
import styled from '@emotion/styled';
import { imageRemapSize } from '../image/image-remap-size';

const Image = styled.img({
  display: 'block',
  minWidth: '100%',
  maxWidth: '100%',
  minHeight: '100%',
  maxHeight: '100%',
  objectFit: 'cover',
  objectPosition: '50% 50%',
});

type Props = {
  desktopImageUrl: string;
  mobileImageUrl: string;
  shouldBlur?: boolean;
  loading?: 'lazy' | 'eager';
  className?: string;
};

export const FullWidthImage: FC<Props> = ({ mobileImageUrl, desktopImageUrl, shouldBlur, className, loading }) => {
  const blur = shouldBlur ? 16 : 0;

  const mobileSrcSet = imageRemapSize({ sizes: [360, 412, 800], src: mobileImageUrl, blur });
  const desktopSrcSet = imageRemapSize({ sizes: [960, 1366, 1920, 2500], src: desktopImageUrl, blur });

  return (
    <picture className={className}>
      <source
        srcSet={mobileSrcSet}
        media={`
          (max-width: 500px),
          (max-width: 926px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)
        `}
      />
      <source srcSet={desktopSrcSet} />
      <Image alt="" loading={loading} />
    </picture>
  );
};
