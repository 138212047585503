import styled from '@emotion/styled';

export const FullCover = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  aspectRatio: 'var(--aspect-ratio)',
  borderRadius: 'var(--radius)',
});
