export type AspectRatio = 'portrait' | 'landscape' | 'square';
export type ColumnWidth = 'narrow' | 'wide';

export enum PartsZIndex {
  HeaderLink = 1,
  GameLogo = 20,
  MonetaryDetails = 50, //Jackpot and BetInfo
  Turnover = 90,
  FocusRing = 91,
}

export const AspectRatioValues = {
  portrait: {
    ratio: '2 / 3',
    percentage: '150%',
  },
  landscape: {
    ratio: '16 / 9',
    percentage: '56.25%',
  },
  square: {
    ratio: '1 / 1',
    percentage: '100%',
  },
};
