const SANITY_CDN_URL = 'https://cdn.sanity.io';

type MediaLoaderProps = {
  src: string;
};

/**
 * Custom media loader that goes through a cloudflare worker to the
 * sanity cdn in order to utilize the cloudflare cache.
 */
export const mediaLoader = ({ src }: MediaLoaderProps) => {
  if (src.includes(SANITY_CDN_URL)) {
    return `/static/sanity${src.replace(SANITY_CDN_URL, '')}`;
  }
  return src;
};
