import styled from '@emotion/styled';
import type { FC } from 'react';
import { useMemo, useRef, useState } from 'react';
import { usePaginationFragment, graphql, useFragment } from 'react-relay/hooks';
import { toast } from 'react-toastify';
import { Breakpoint } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { useTrackClickedGame, useTrackViewedGame } from '@pafcloud/contexts';
import {
  FlexibleGameListCover,
  FlexibleGameListDecorations,
  FlexibleGameListGameInfo,
  FlexibleGameListGameLink,
  FlexibleGameListGameListHeader,
  FlexibleGameListGrid,
  FlexibleGameListHeading,
  FlexibleGameListListItem,
  FlexibleGameListShowMoreButton,
  FlexibleGameListThumbnail,
} from '../flexible-game-list';
import { getPortraitImageSizes } from '../flexible-game-list/grid';
import { GameListSection } from '../flexible-game-list/parts';
import { useGridColumns } from '../flexible-game-list/useGridColumns';
import { getPaginatedGrid } from '../flexible-game-list/utils/getPaginatedGrid';
import type { GameList_gameList$key } from './__generated__/GameList_gameList.graphql';
import type { GameList_trackable$key } from './__generated__/GameList_trackable.graphql';
import type { GameListPaginationQuery } from './__generated__/GameListPaginationQuery.graphql';

const NewGameListSection = styled(GameListSection)({
  '--full-width-margin': '0px',
  display: 'grid',
});

const Grid = styled(FlexibleGameListGrid)({
  '--narrow-span': 2,

  [Breakpoint.TabletOrLarger]: {
    '--columns': 4,
    '--narrow-span': 1,
  },
  [Breakpoint.LaptopOrLarger]: {
    '--columns': 4,
    '--narrow-span': 1,
  },
  [Breakpoint.BigScreenOrLarger]: {
    '--columns': 4,
    '--narrow-span': 1,
  },
  [Breakpoint.HDScreenOrLarger]: {
    '--columns': 4,
    '--narrow-span': 1,
  },
});

const gameListFragment = graphql`
  fragment GameList_gameList on GameList
  @argumentDefinitions(count: { type: Int, defaultValue: 40 }, cursor: { type: String }, tag: { type: String })
  @refetchable(queryName: "GameListPaginationQuery") {
    internalName
    content
    ...useTrackGame_gameList
    games(first: $count, after: $cursor, tag: $tag) @connection(key: "GameList_gameList_games") {
      totalCount
      recommendationId
      ...useTrackGame_games
      edges {
        node {
          ...GameLink_game
          ...Decorations_game
          name
          supplier
          slug
          thumbnail(layout: SQUARE)
        }
      }
    }
  }
`;

const trackableFragment = graphql`
  fragment GameList_trackable on Trackable {
    ...useTrackGame_trackable
  }
`;

type GameListProps = {
  tag?: string;
  gameList: GameList_gameList$key;
  trackable: GameList_trackable$key | null;
  numberOfRows: number;
  highlightedGameSlugs?: readonly string[];
  showTotalGameCount?: boolean | null;
  title?: string | null;
};

export const GameList: FC<GameListProps> = ({ title, numberOfRows: initialNumberOfRows, ...props }) => {
  const gridRef = useRef<HTMLUListElement>(null);
  const { t } = useTranslation('game-list');
  const imageSizes = useMemo(() => getPortraitImageSizes('narrow'), []);
  const numberOfColumns = useGridColumns(gridRef, 'narrow');
  const [rows, setRows] = useState(initialNumberOfRows);
  const trackable = useFragment(trackableFragment, props.trackable);
  const pagination = usePaginationFragment<GameListPaginationQuery, GameList_gameList$key>(
    gameListFragment,
    props.gameList,
  );

  const { games } = pagination.data;

  const onGameViewed = useTrackViewedGame({
    games,
    gameList: pagination.data,
    trackable: trackable ?? null,
  });

  const onGameClicked = useTrackClickedGame({
    games,
    gameList: pagination.data,
    trackable: trackable ?? null,
  });

  const paginatedGrid = getPaginatedGrid(pagination, {
    edges: games.edges,
    columns: numberOfColumns,
    rows,
    setRows,
    onError: () => toast.error(t('load-more-failed')),
  });

  const { data: visibleGames, loadNext, hasNext, isLoadingNext } = paginatedGrid;

  if (visibleGames.length === 0) {
    return null;
  }

  return (
    <NewGameListSection aspectRatio="square" columnWidth="narrow">
      {title && (
        <FlexibleGameListGameListHeader>
          <FlexibleGameListHeading title={title} />
        </FlexibleGameListGameListHeader>
      )}
      <Grid ref={gridRef}>
        {visibleGames.map(({ node: game }, index) => (
          <FlexibleGameListListItem key={game.slug}>
            <FlexibleGameListGameLink game={game} onClick={onGameClicked} index={index} onView={onGameViewed}>
              <FlexibleGameListCover>
                <FlexibleGameListThumbnail gameName={game.name} thumbnail={game.thumbnail} sizes={imageSizes} />
                <FlexibleGameListDecorations game={game} />
              </FlexibleGameListCover>
              <FlexibleGameListGameInfo name={game.name} supplier={game.supplier} />
            </FlexibleGameListGameLink>
          </FlexibleGameListListItem>
        ))}
      </Grid>

      {hasNext && (
        <FlexibleGameListShowMoreButton
          variant="secondary"
          colorstyle="accent"
          type="button"
          size="default"
          isLoading={isLoadingNext}
          onClick={loadNext}
        >
          {t('show-more')}
        </FlexibleGameListShowMoreButton>
      )}
    </NewGameListSection>
  );
};
