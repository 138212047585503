import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { FlexibleGameListColors } from '../flexible-game-list-colors';
import type { ShowTotalGameCount_block$key } from './__generated__/ShowTotalGameCount_block.graphql';
import type { ShowTotalGameCount_games$key } from './__generated__/ShowTotalGameCount_games.graphql';

const blockFragment = graphql`
  fragment ShowTotalGameCount_block on CommonGameListBlock {
    showTotalGameCount
  }
`;

const gamesFragment = graphql`
  fragment ShowTotalGameCount_games on GameListGames_Connection {
    totalCount
  }
`;

const ShowTotalGameCountContainer = styled.span(
  {
    marginLeft: 8,
    lineHeight: 1,
    color: FlexibleGameListColors.GameInfoExtraInfoText,
  },
  () =>
    $buildEnv.theme === 'classic' && {
      transform: 'translateY(0.25em)', // Fix Adelle font baseline
    },
);

type ShowTotalGameCountProps = {
  block: ShowTotalGameCount_block$key;
  games: ShowTotalGameCount_games$key;
};

export const ShowTotalGamesCount: FC<ShowTotalGameCountProps> = ({ block, games }) => {
  const { showTotalGameCount } = useFragment(blockFragment, block);
  const { totalCount } = useFragment(gamesFragment, games);

  if (!showTotalGameCount || !totalCount) {
    return null;
  }

  return <ShowTotalGameCountContainer>({totalCount})</ShowTotalGameCountContainer>;
};
