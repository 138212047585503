import type { FC } from 'react';
import { useMemo, useState, useRef } from 'react';
import { graphql, useFragment, usePaginationFragment } from 'react-relay/hooks';
import { toast } from 'react-toastify';
import { useTranslation } from '@pafcloud/i18n';
import { useTrackClickedGame, useTrackViewedGame } from '@pafcloud/contexts';
import type { GridGameList_block$key } from './__generated__/GridGameList_block.graphql';
import type { GridGameList_gameList$key } from './__generated__/GridGameList_gameList.graphql';
import type { PaginatedGridGameListQuery } from './__generated__/PaginatedGridGameListQuery.graphql';
import {
  Cover,
  GameListHeader,
  GameListSection,
  Heading,
  ListItem,
  ShowMoreButton,
  Thumbnail,
  GameLink,
  SeeAllGamesLink,
  Grid,
} from './parts';
import { Decorations } from './decorations/Decorations';
import { useGridColumns } from './useGridColumns';
import { getPaginatedGrid } from './utils/getPaginatedGrid';
import { ShowTotalGamesCount } from './parts/ShowTotalGameCount';
import { getPortraitImageSizes } from './grid';

const gridGameListFragment = graphql`
  fragment GridGameList_gameList on GameList
  @argumentDefinitions(count: { type: Int, defaultValue: 40 }, cursor: { type: String })
  @refetchable(queryName: "PaginatedGridGameListQuery") {
    games(first: $count, after: $cursor) @connection(key: "PaginatedGridGameList_GameList_games") {
      ...useTrackGame_games
      ...ShowTotalGameCount_games
      edges {
        node {
          ...GameLink_game
          ...Decorations_game
          ...GameLogo_game
          name
          supplier
          slug
          thumbnail(layout: PORTRAIT)
        }
      }
    }
  }
`;

const gameListBlockFragment = graphql`
  fragment GridGameList_block on GridGameListBlock {
    gameList {
      ...GridGameList_gameList
      ...useTrackGame_gameList
    }
    title
    numberOfRows
    ...useTrackGame_trackable
    ...SeeAllGamesLink_block
    ...ShowTotalGameCount_block
  }
`;

type Props = {
  block: GridGameList_block$key;
};

export const GridGameList: FC<Props> = (props) => {
  const gridRef = useRef<HTMLUListElement>(null);
  const { t } = useTranslation('game-list');
  const imageSizes = useMemo(() => getPortraitImageSizes('narrow'), []);

  const numberOfColumns = useGridColumns(gridRef, 'narrow');
  const blockData = useFragment(gameListBlockFragment, props.block);
  const [rows, setRows] = useState(blockData.numberOfRows);
  const pagination = usePaginationFragment<PaginatedGridGameListQuery, GridGameList_gameList$key>(
    gridGameListFragment,
    blockData.gameList,
  );
  const { games } = pagination.data;

  const onGameClicked = useTrackClickedGame({
    games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  const onGameViewed = useTrackViewedGame({
    games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  const paginatedGrid = getPaginatedGrid(pagination, {
    edges: games.edges,
    columns: numberOfColumns,
    rows,
    setRows,
    onError: () => toast.error(t('load-more-failed')),
  });
  const { data: visibleGames, loadNext, hasNext, isLoadingNext } = paginatedGrid;

  if (visibleGames.length === 0) {
    return null;
  }

  return (
    <GameListSection aspectRatio="portrait" columnWidth="narrow">
      <GameListHeader>
        <Heading title={blockData.title} />
        <SeeAllGamesLink block={blockData} />
        <ShowTotalGamesCount block={blockData} games={games} />
      </GameListHeader>

      <Grid ref={gridRef}>
        {visibleGames.map(({ node: game }, index) => (
          <ListItem key={game.slug}>
            <GameLink game={game} onClick={onGameClicked} index={index} onView={onGameViewed}>
              <Cover>
                <Thumbnail gameName={game.name} thumbnail={game.thumbnail} sizes={imageSizes} />
                <Decorations game={game} />
              </Cover>
            </GameLink>
          </ListItem>
        ))}
      </Grid>

      {hasNext && (
        <ShowMoreButton
          variant="secondary"
          colorstyle="accent"
          type="button"
          size="default"
          isLoading={isLoadingNext}
          onClick={loadNext}
        >
          {t('show-more')}
        </ShowMoreButton>
      )}
    </GameListSection>
  );
};
