import type { FC } from 'react';
import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { useTrackClickedGame, useTrackViewedGame } from '@pafcloud/contexts';
import { Breakpoint } from '@pafcloud/style';
import {
  Cover,
  GameListHeader,
  Heading,
  ListItem,
  Thumbnail,
  GameLink,
  FlexibleGameListTitle,
  GameList,
} from './parts';
import { getLandscapeImageSizes } from './grid';
import { GameListBackground } from './parts/GameListBackground';
import { Decorations } from './decorations/Decorations';
import type { HighlightGameList_block$key } from './__generated__/HighlightGameList_block.graphql';
import { FlexibleGameListColors } from './flexible-game-list-colors';
import { AspectRatioValues } from './definitions';

const gameListBlockFragment = graphql`
  fragment HighlightGameList_block on HighlightGameListBlock {
    gameList {
      ...useTrackGame_gameList
      games(first: 3) {
        ...useTrackGame_games
        ...ShowTotalGameCount_games
        edges {
          node {
            ...GameLink_game
            ...Decorations_game
            name
            slug
            thumbnailLandscape: thumbnail(layout: LANDSCAPE)
            thumbnailPortrait: thumbnail(layout: PORTRAIT)
          }
        }
      }
    }
    title
    ...useTrackGame_trackable
    gameListBackground {
      ...GameListBackground
    }
  }
`;

const LIST_BREAKPOINT = Breakpoint.TabletOrLarger;

const HighlightGameListSection = styled(GameList)({
  padding: '64px 0',

  '--aspect-ratio': AspectRatioValues.portrait.ratio,
  '--aspect-ratio-percentage': AspectRatioValues.portrait.percentage,

  [LIST_BREAKPOINT]: {
    '--aspect-ratio': AspectRatioValues.landscape.ratio,
    '--aspect-ratio-percentage': AspectRatioValues.landscape.percentage,
  },
});

const List = styled.ul({
  overflowX: 'scroll',
  scrollPadding: 'var(--full-width-margin)',
  scrollSnapType: 'x mandatory',

  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: 'calc((80vw - (var(--full-width-margin) * 2)) - var(--gap))',
  gap: 'var(--gap)',

  margin: '-48px 0',
  padding: '48px var(--full-width-margin)',
  listStyle: 'none',

  [LIST_BREAKPOINT]: {
    overflow: 'unset',
    gridAutoColumns: 'var(--grid-item)',
  },
});

const HighlightGameListHeader = styled(GameListHeader)({
  [`${FlexibleGameListTitle}`]: {
    color: FlexibleGameListColors.HighlightGameListHeaderText,
  },
});

const CoverBorder = styled(Cover)({
  '--frame-border-offset': '2px',
  background: FlexibleGameListColors.HighlightGameListFrameBorder,
  borderRadius: `calc(var(--radius) + var(--frame-border-offset))`,
  border: `var(--frame-border-offset) solid transparent`,

  [Breakpoint.LaptopOrLarger]: {
    '--frame-border-offset': '4px',
  },
});

const DesktopThumbnail = styled(Thumbnail)({
  display: 'none',

  [LIST_BREAKPOINT]: {
    display: 'flex',
  },
});

const MobileThumbnail = styled(Thumbnail)({
  display: 'flex',

  [LIST_BREAKPOINT]: {
    display: 'none',
  },
});

type Props = {
  block: HighlightGameList_block$key;
};

export const HighlightGameList: FC<Props> = ({ block }) => {
  const blockData = useFragment(gameListBlockFragment, block);
  const imageSizes = useMemo(() => getLandscapeImageSizes('wide'), []);

  const onGameClicked = useTrackClickedGame({
    games: blockData.gameList.games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  const onGameViewed = useTrackViewedGame({
    games: blockData.gameList.games,
    gameList: blockData.gameList,
    trackable: blockData,
  });

  if (!blockData.gameList.games.edges.length) {
    return null;
  }

  return (
    <HighlightGameListSection data-column-width="narrow">
      <GameListBackground block={blockData.gameListBackground} />
      <HighlightGameListHeader>
        <Heading title={blockData.title} />
      </HighlightGameListHeader>

      <List>
        {blockData.gameList.games.edges.map(({ node: game }, index) => (
          <ListItem key={game.slug}>
            <GameLink game={game} onClick={onGameClicked} index={index} onView={onGameViewed}>
              <CoverBorder>
                <DesktopThumbnail gameName={game.name} thumbnail={game.thumbnailLandscape} sizes={imageSizes} />
                <MobileThumbnail gameName={game.name} thumbnail={game.thumbnailPortrait} sizes={imageSizes} />
                <Decorations game={game} />
              </CoverBorder>
            </GameLink>
          </ListItem>
        ))}
      </List>
    </HighlightGameListSection>
  );
};
